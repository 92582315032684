import { getXMLValueFromJSON } from 'helper/xmlParser';
import { IFailReason } from 'types';
import Countries from 'constants/Countries.json';
import { EDocumentType, EImageSide, EReason, EReturnCodes, EVersionId } from 'enums';
import { IDataXML, IDocumentTypeData, ISubDocData } from 'store/features/zipContent/types';
import { ICheckResult, IDocumentData, IFilesData } from 'store/features/checkResult/types';
import { ANSWERS_KEYS, OTHER_IMAGE_SIDES } from 'constants/Static';
import { checkPackageDocumentDifference } from 'helper/tools/quickCheck';

const documentMismatchDetected = (metadata: any) => {
  const {
    checkDocNumberBarcodeVsVIZ = '', checkLastNameMRZvsVIZ = '', checkDOBMRZvsNFC = '',
    checkFirstNameBarcodeVsVIZ = '', checkDocNumberMRZvsNFC = '', checkDOBMRZvsVIZ = '',
    checkDocNumberMRZvsVIZ = '', checkFirstNameMRZvsNFC = '', checkLastNameMRZvsNFC = '',
    checkLastNameBarcodeVsVIZ = '', checkFirstNameMRZvsVIZ = '', checkDOBBarcodeVsVIZ = '',
  } = metadata;
  return checkFirstNameBarcodeVsVIZ === 'false' || checkDOBBarcodeVsVIZ === 'false'
  || checkFirstNameMRZvsVIZ === 'false' || checkDocNumberMRZvsVIZ === 'false'
  || checkDocNumberMRZvsNFC === 'false' || checkLastNameMRZvsVIZ === 'false'
  || checkFirstNameMRZvsNFC === 'false' || checkLastNameMRZvsNFC === 'false'
  || checkDocNumberBarcodeVsVIZ === 'false' || checkDOBMRZvsNFC === 'false'
  || checkLastNameBarcodeVsVIZ === 'false' || checkDOBMRZvsVIZ === 'false'
}

export const getSDKFails = (data: IDataXML) => {
  const {
    livenessCheck = {},
    metadata = {} as any,
    faceVerification = {},
  } = data.documentScan || {};
  const { errorID: ldError = '' } = livenessCheck;
  const { errorID: fvError = '' } = faceVerification;
  const { errorCodes = '' } = metadata;
  const mismatchDetected = documentMismatchDetected(metadata);

  if (!errorCodes && !ldError && !fvError && !mismatchDetected) {
    return ['reg.review-reasons.missing-error-codes'];
  }
  const collectedErrorCodes = errorCodes.split(', ').filter((errorCode: string) => errorCode);
  if (ldError) collectedErrorCodes.push(ldError);
  if (fvError) collectedErrorCodes.push(fvError);
  if (documentMismatchDetected(metadata)) {
    collectedErrorCodes.push(EReturnCodes.EError_DocumentDataMismatch);
  }

  const result = collectedErrorCodes.reduce((acc: string[], errorCode: string) => {
    let messageKey = '';
    switch (errorCode) {
    case EReturnCodes.EError_IdNotInList:
      messageKey = 'reg.review-reasons.id-not-allowed';
      break;
    case EReturnCodes.EError_DocumentDataMismatch:
      messageKey = 'reg.review-reasons.data-mismatch';
      break;
    case EReturnCodes.EError_IdMatchingFailed:
    case EReturnCodes.EError_IdNotDetected:
    case EReturnCodes.EError_IdPageMissing:
    case EReturnCodes.EError_IdMismatch:
      messageKey = 'reg.review-reasons.unrecognized-id';
      break;
    case EReturnCodes.EError_IdFaceImageCaptureFailed:
      messageKey = 'reg.review-reasons.face-not-extracted';
      break;
    case EReturnCodes.EError_IdNoData:
    case EReturnCodes.EError_IdIncompleteData:
      messageKey = 'reg.review-reasons.incomplete-data-extraction';
      break;
    case EReturnCodes.EError_FaceNotRecognised:
    case EReturnCodes.EError_FaceNotVerified:
      messageKey = 'reg.review-reasons.fv';
      break;
    case EReturnCodes.EError_FaceLivenessFailed:
      messageKey = 'reg.review-reasons.ld';
      break;
    case EReturnCodes.EError_ImageDocumentTooFar:
    case EReturnCodes.EError_ImageBlured:
    case EReturnCodes.EError_Reflection:
      messageKey = 'reg.review-reasons.image-quality';
      break;
    case EReturnCodes.EError_ImageInjection:
      messageKey = 'reg.review-reasons.fraud-mounted-device-attack';
      break;
    case EReturnCodes.EError_ImageIsGreyscale:
      messageKey = 'reg.review-reasons.fraud-greyscale-attack';
      break;
    case EReturnCodes.EError_ImageFromScreen:
      messageKey = 'reg.review-reasons.fraud-screen-attack';
      break;
    case EReturnCodes.EError_IdBadMrzFields:
    case EReturnCodes.EError_IdBadMrzFieldBirthDay:
    case EReturnCodes.EError_IdBadMrzFieldCompositCheckDigit:
    case EReturnCodes.EError_IdBadMrzFieldExpiryDay:
    case EReturnCodes.EError_IdBadMrzFieldDocumentNumber:
    case EReturnCodes.EError_IdBadMrzFieldGender:
    case EReturnCodes.EError_IdBadMrzFieldCountry:
    case EReturnCodes.EError_IdBadMrzFieldNationality:
    case EReturnCodes.EError_MRZ_NotDetected:
      messageKey = 'reg.review-reasons.mrz';
      break;
    case EReturnCodes.EError_NFC_TagWasLost:
    case EReturnCodes.EError_NFC_NotConnected:
    case EReturnCodes.EError_NFC_MutualAuthenticationFailedUnknown:
    case EReturnCodes.EError_NFC_MutualAuthenticationFailedNotSatisfied:
    case EReturnCodes.EError_NFC_ReadFailed:
    case EReturnCodes.EError_NFC_UnexpectedException:
    case EReturnCodes.EError_NFC_Timeout:
    case EReturnCodes.EError_NFC_NotSupported:
    case EReturnCodes.EError_NFC_CertificateValidationFailed:
    case EReturnCodes.EError_NFC_TechnicalError:
    case EReturnCodes.EError_NFC_CLONED_CHIP:
    case EReturnCodes.EError_NFC_TEMPERED_DATA:
    case EReturnCodes.EError_NFC_SOD_VS_COM_INSONSITENCY:
    case EReturnCodes.EError_NFC_INVALID_HASHES:
      messageKey = 'reg.review-reasons.nfc';
      break;
    default:
      messageKey = 'reg.review-reasons.default';
    }
    if (!acc.includes(messageKey)) acc.push(messageKey);
    return acc;
  }, []);
  return result;
};

export const getSDKField = (data: IDataXML, field: string) => {
  const { documentScan = {} as any } = data;
  const { mrz, viz } = documentScan;
  if (!mrz && !viz) {
    return '';
  }

  if (mrz) {
    return getXMLValueFromJSON(mrz[field]);
  }
  return getXMLValueFromJSON(viz[field]);
};

const getCountryTranslation = (country: string) => {
  const countryData = Countries.find((option) => option.value === country);
  if (countryData?.alias) return countryData.alias;
  if (countryData) return countryData.label;
  return country;
};

export const getDocTypeByCountryDoc = (docType: string, country: string) => {
  switch (docType) {
  case EDocumentType.id:
    return [getCountryTranslation(country), 'documentType.id'];
  case EDocumentType.passport:
    return [getCountryTranslation(country), 'documentType.passport'];
  case EDocumentType.paperPermit:
    return [getCountryTranslation(country), 'documentType.paper-permit'];
  case EDocumentType.plasticPermit:
    return [getCountryTranslation(country), 'documentType.plastic-permit'];
  case EDocumentType.drivingLicence:
    return [getCountryTranslation(country), 'documentType.drivingLicence'];
  case EDocumentType.healthCard:
    return [getCountryTranslation(country), 'documentType.healthCard'];
  default:
    return ['documentType.unknown-document'];
  }
};

export const getDocType = (documentTypeData: IDocumentTypeData) => {
  const { documentType = '', documentCountry = '' } = documentTypeData;
  if (documentType && documentCountry) {
    return getDocTypeByCountryDoc(documentType, documentCountry);
  }
  return ['documentType.unknown-document'];
};

export const getIsLivenessFailedSDK = (data: IDataXML) => (
  getXMLValueFromJSON(data?.documentScan?.livenessCheck?.result) === 'fail'
);

const getExpirationReason = (dataXML: IDataXML = {}): IFailReason => {
  const currentDate = new Date();
  const { viz = {}, mrz = {} } = dataXML?.documentScan || {};
  let expiryDate = currentDate;
  if (mrz.expirationDate) {
    expiryDate = new Date(getXMLValueFromJSON(mrz.expirationDate));
  } else if (viz.expirationDate) {
    expiryDate = new Date(getXMLValueFromJSON(viz.expirationDate));
  }
  const expired = expiryDate.getTime() < currentDate.getTime();
  return expired ? {
    text: 'reg.data.expired-document',
    reason: EReason.expiredDocument,
    tab: 'System',
  } : {} as IFailReason;
};

export const generateManualCheckFailReasons = (
  checkResult: ICheckResult,
  subDocsData: ISubDocData[],
) => {
  const failReasonsData: IFailReason[] = [];
  const { comparingData, filesData, documentData, livenessData, faceComparingData } = checkResult;
  if (Object.keys(comparingData).length) {
    failReasonsData.push({
      text: comparingData.quality,
      reason: comparingData.reason,
      tab: 'reg.summary.quickCheck',
    });
  }
  if (Object.keys(faceComparingData).length) {
    if (faceComparingData.reasons?.length) {
      faceComparingData.reasons.forEach((reason) => {
        failReasonsData.push({
          text: reason,
          reason: faceComparingData.reason,
          tab: 'reg.summary.quickCheck',
        });
      })
    }
  }
  if (filesData.length) {
    filesData.forEach((subDoc: IFilesData[]) => {
      subDoc.forEach((file: IFilesData) => {
        if (file.reviewResult.reasons?.length) {
          file.reviewResult.reasons.forEach((reason) => {
            if (!failReasonsData.find(({ text }) => text === reason)) {
              failReasonsData.push({
                text: reason,
                reason: file.reviewResult.reason,
                tab: 'reg.summary.quickCheck',
              });
            }
          });
        }
      });
    });
  }
  subDocsData.forEach((subDocsData: ISubDocData, subDocIndex: number) => {
    const validImage = filesData[subDocIndex].find((fileData) => (
      fileData.imageSide && !OTHER_IMAGE_SIDES.includes(fileData.imageSide)
    ));
    const { documentType } = validImage || {};
    const hasSameDocumentData = checkPackageDocumentDifference(filesData[subDocIndex]);
    if (hasSameDocumentData) {
      const fakeDocument = filesData[subDocIndex].find((fileData) => (
        fileData.reviewResult?.key === ANSWERS_KEYS.FRAUD
      ));
      if (fakeDocument) {
        failReasonsData.push({
          text: 'reg.files.fakeDocument',
          reason: EReason.fakeDocument,
          tab: 'reg.summary.quickCheck',
        });
      }
      const badQualityImage = filesData[subDocIndex].find((fileData) => (
        fileData.reviewResult?.key === ANSWERS_KEYS.UNSURE
      ));
      if (badQualityImage) {
        failReasonsData.push({
          text: 'reg.files.badQuality',
          reason: EReason.badQuality,
          tab: 'reg.summary.quickCheck',
        });
      }
      const frontImages = filesData[subDocIndex].filter((fileData) => (
        fileData.imageSide === EImageSide.front
      ));
      if (documentType !== EDocumentType.healthCard && !frontImages.length) {
        failReasonsData.push({
          reason: EReason.frontOfIdMissing,
          text: 'reg.files.frontOfIdMissing',
          tab: 'reg.summary.quickCheck',
        });
      }
      if (frontImages.find((image) => image.reviewResult?.key === ANSWERS_KEYS.UNSURE)) {
        failReasonsData.push({
          reason: EReason.frontOfIdBadQuality,
          text: 'reg.files.frontOfIdBadQuality',
          tab: 'reg.summary.quickCheck',
        });
      }
      const backImages = filesData[subDocIndex].filter((fileData) => (
        fileData.imageSide === EImageSide.back
      ));
      if (documentType !== EDocumentType.passport && !backImages.length) {
        failReasonsData.push({
          reason: EReason.backOfIdMissing,
          text: 'reg.files.backOfIdMissing',
          tab: 'reg.summary.quickCheck',
        });
      }
      if (backImages.find((image) => image.reviewResult?.key === ANSWERS_KEYS.UNSURE)) {
        failReasonsData.push({
          reason: EReason.backOfIdBadQuality,
          text: 'reg.files.backOfIdBadQuality',
          tab: 'reg.summary.quickCheck',
        });
      }
    } else {
      failReasonsData.push({
        text: 'reg.files.mismatchDocument',
        reason: EReason.mismatchDocument,
        tab: 'reg.summary.quickCheck',
      });
    }
    const expirationReason = getExpirationReason(subDocsData.dataXML);
    if (Object.keys(expirationReason).length) {
      failReasonsData.push(expirationReason);
    }
  });
  if (Object.keys(livenessData).length) {
    if (livenessData.reasons?.length) {
      livenessData.reasons.forEach((reason) => {
        failReasonsData.push({
          text: reason,
          reason: livenessData.reason,
          tab: 'reg.summary.fraudCheck',
        });
      })
    }
  }
  if (documentData.length) {
    documentData.forEach((document: IDocumentData[]) => {
      document.forEach((file: IDocumentData) => {
        if (file.reviewResult.reasons?.length) {
          file.reviewResult.reasons.forEach((reason) => {
            if (!failReasonsData.find(({ text }) => text === reason)) {
              failReasonsData.push({
                text: reason,
                reason: file.reviewResult.reason,
                tab: 'reg.summary.fraudCheck',
              });
            }
          });
        }
      });
    });
  }
  return failReasonsData;
};

export const getSDKVersion = (dataXML: IDataXML) => {
  const { versionId } = dataXML.documentScan?.metadata || {};
  return versionId?.startsWith(EVersionId.v3) ? EVersionId.v3 : EVersionId.v2;
}
