import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Logo } from 'images/pxlCheckLogo.svg';
import { ReactComponent as PersonIcon } from 'images/personIcon.svg';
import useOutsideClick from 'hooks/useOutsideClick';
import SwitchLanguage from 'components/lang/SwitchLanguage';
import { logOut } from 'util/authService';
import { PATH } from 'constants/Static';
import { GetAppVersion } from 'helper/api/route';

const { DATASET } = PATH;

const TopHeader = () => {
  const { t } = useTranslation();
  const [isMenuOpened, toggleMainMenu] = useState(false);
  const userRef = useRef(null);
  useOutsideClick(userRef, toggleMainMenu);
  const history = useHistory();
  const [version, setVersion] = useState('');

  const navigateToDataset = () => {
    toggleMainMenu(!isMenuOpened);
    history.replace(DATASET);
  };

  useEffect(() => {
    GetAppVersion().then((response) => {
      setVersion(response.data);
    });
  }, []);

  return (
    <div className="top-header-container">
      <div>
        <div className='logo'>
          <Logo />
          <p>{ 'v' + version }</p>
        </div>
      </div>
      <div className="user-logo-container">
        <SwitchLanguage />
        <div className="person-icon-container" ref={userRef}>
          <PersonIcon
            tabIndex={0}
            onClick={(() => toggleMainMenu(!isMenuOpened))}
            onKeyPress={ () => toggleMainMenu(!isMenuOpened)}
          />
          {isMenuOpened && (
            <div className="menu-dropdown">
              <div className="menu-dropdown-item">
                <span
                  className="popup-content"
                  tabIndex={0}
                  onClick={() => logOut()}
                  onKeyPress={() => logOut()}
                >
                  {t('top-header.log-out')}
                </span>
              </div>
              <div className="menu-dropdown-item">
                <span
                  tabIndex={0}
                  onClick={navigateToDataset}
                  onKeyPress={navigateToDataset}
                  className="popup-content"
                >
                  {t('top-header.set-datasets')}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
