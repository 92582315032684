import { TFunction } from 'react-i18next';

import { ANSWERS_KEYS, OTHER_IMAGE_SIDES, PASS, SOMETHING } from 'constants/Static';
import { isUnknownDocument, toTitleCase } from 'helper/tools/commonHelper';
import { ISubDocData } from 'store/features/zipContent/types';
import { IExpireDateData, IFilesData } from 'store/features/checkResult/types';
import { EDirection, EDocumentType, EImageSide, EZone } from 'enums';
import { detectImageCropping } from 'util/common';
import { ISpecimen } from 'store/features/specimen/types';
import Countries from 'constants/Countries.json';
import DocumentTypes from 'constants/DocumentTypes.json';
import { IViews, Questions } from 'store/features/common/types';
import { getXMLValueFromJSON, oneSideDocument } from 'helper/xmlParser';
import { getSDKVersion } from 'util/helperSDKVerification';

const hasFacePage = (subDocsData: ISubDocData[], video: string) => (
  video && subDocsData?.some((subDoc) => subDoc.avatar)
);

const validateFace = (subDocsData: ISubDocData[], video: string, isSimplified: boolean) => {
  const isFvPassed = subDocsData?.every((docData) => (
    !docData.dataXML.documentScan?.faceVerification?.result ||
    docData.dataXML.documentScan?.faceVerification?.result !== PASS
  ));
  return(!isSimplified || isFvPassed) && hasFacePage(subDocsData, video);
};

const getExpireDateData = (
  subDocsData: ISubDocData[],
  filesData: IFilesData[][],
  views: IViews,
) => {
  if (!views.date) return [];
  return filesData.reduce((
    acc: IExpireDateData[],
    fileItems: IFilesData[],
    subDocIndex: number,
  ) => {
    const { mrz = {} } = subDocsData[subDocIndex]?.dataXML?.documentScan || {};
    if (mrz?.expirationDate) {
      const expirationDate = getXMLValueFromJSON(mrz?.expirationDate);
      const availableImageIndexes = fileItems.reduce((
        acc: number[],
        fileItem: IFilesData,
        index: number,
      ) => {
        if (fileItem.isImageCropped) {
          acc.push(index);
        }
        return acc;
      }, []);
      if (availableImageIndexes.length) {
        const backImageIndex = fileItems.findIndex(({ imageSide, isImageCropped }) => (
          isImageCropped && imageSide === EImageSide.back
        ));
        const imageIndex = backImageIndex === -1 ? availableImageIndexes[0] : backImageIndex;
        acc.push({
          subDocIndex,
          imageIndex,
          availableImageIndexes,
          expirationDate,
          reviewResult: { quality: '' }
        });
      }
    }
    return acc;
  }, []);
}

const getFilesData = async (subDocsData: ISubDocData[]) => (
  await Promise.all(subDocsData.map(async (subDoc: ISubDocData) => {
    const { documentTypeData, files, dataXML } = subDoc;
    const versionId = getSDKVersion(dataXML);
    const {
      documentType,
      documentCountry,
      documentSubtype,
    } = documentTypeData;
    return await Promise.all(files.map(async (file) => {
      const filename = Object.keys(file)[0];
      let imageSide = ('' as EImageSide);
      let pageSideKey = '';
      if (oneSideDocument(documentType) && (
        filename.includes(EZone.viz) || filename.includes(EZone.mrz)
      )) {
        imageSide = EImageSide.front;
        pageSideKey ='reg.files.documentPage.answers.data';
      } else {
        if (filename.includes(EZone.viz)) {
          imageSide = EImageSide.front;
          pageSideKey ='reg.files.documentPage.answers.front';
        }
        if (filename.includes(EZone.mrz)) {
          imageSide = EImageSide.back;
          pageSideKey ='reg.files.documentPage.answers.back';
        }
      }
      const cropped = await detectImageCropping(file, versionId);
      return {
        imageSide,
        pageSideKey,
        country: documentCountry,
        documentType,
        isImageCropped: !!cropped,
        extraDetail: documentSubtype || '',
        reviewResult: {
          quality: '',
        },
      };
    }));
  }))
);

const collectExtraDetailOptions = (specimens: ISpecimen[], t: TFunction, includeOther = false) => {
  let result = specimens.map((item: ISpecimen) => ({
    label: toTitleCase(item.extraDetail),
    value: item.extraDetail,
  }));
  if (includeOther) {
    result = result.concat([{
      label: t('reg.your.selection.title.extra-detail.other'),
      value: ANSWERS_KEYS.OTHER,
    }]);
  }
  return result.filter((a, i) => (
    result.findIndex((option) => a.label === option.label) === i)
  );
}

const getDocumentTypeValue = (value: string, t: TFunction) => {
  const documentTypeData = DocumentTypes.find((option) => option.value === value);
  if (documentTypeData?.value) {
    return {
      label: t(documentTypeData.label),
      value: documentTypeData.value,
    };
  }
  if (value) {
    return {
      label: t('reg.files.document-country.unknown'),
      value: EDocumentType.something,
    };
  }
  return null;
};

const getCountryValue = (value: string, t: TFunction) => {
  const countryData = Countries.find((option) => option.value === value);
  if (countryData?.value) {
    return {
      label: `${t(countryData.label)} (${countryData.value})`,
      value: countryData.value,
    };
  }
  if (value) {
    return {
      label: t('reg.files.document-country.unknown'),
      value: SOMETHING,
    };
  }
  return null;
};

const getExtraDetailValue = (specimens: ISpecimen[], value: string, t: TFunction) => {
  const extraDetails = collectExtraDetailOptions(specimens, t, true);
  const extraDetailData = extraDetails.find((option) => option.value === value);
  if (extraDetailData?.value) {
    return {
      label: extraDetailData.label,
      value: extraDetailData.value,
    };
  }
  return null;
};

export const areAllUnknownDocument = (filesData: IFilesData[]) => (
  filesData.every((image) => isUnknownDocument(image))
)

const detectForcedRejection = (filesData: IFilesData[][]) => (
  filesData.every((item) => {
    const areAllUnknown = areAllUnknownDocument(item);
    const areAllOtherSides = item.every((image) => OTHER_IMAGE_SIDES.includes(image.imageSide));
    const goodToUseImg = item.some((image) => (
      !OTHER_IMAGE_SIDES.includes(image.imageSide) && !image.reviewResult.key
    ));
    return areAllUnknown || areAllOtherSides || !goodToUseImg;
  })
)

const skipFile = (file: IFilesData, question: Questions | undefined, direction: EDirection) => {
  if (question === Questions.quality) {
    return OTHER_IMAGE_SIDES.includes(file.imageSide) || isUnknownDocument(file);
  }
  if (question === Questions.documentPage) {
    return (file.imageSide && direction === EDirection.right) || isUnknownDocument(file);
  }
  if (question !== Questions.countryDocType) {
    return isUnknownDocument(file);
  }
  return false;
}

const getNext = (
  filesData: IFilesData[][],
  subDocIndex: number,
  imageIndex: number,
  direction: EDirection,
  question?: Questions,
): string => {
  if (direction === EDirection.right) {
    if (subDocIndex === filesData.length - 1 && imageIndex === filesData[subDocIndex].length - 1) {
      return '';
    }
    if (imageIndex === filesData[subDocIndex].length - 1) {
      const file = filesData[subDocIndex + direction][0];
      if (skipFile(file, question, direction)) {
        return getNext(filesData, subDocIndex + direction, 0, direction, question);
      }
      return `${subDocIndex + direction}/0`;
    }
    const file = filesData[subDocIndex][imageIndex + direction];
    if (skipFile(file, question, direction)) {
      return getNext(filesData, subDocIndex, imageIndex + direction, direction, question);
    }
    return `${subDocIndex}/${imageIndex + direction}`;
  }
  if (direction === EDirection.left) {
    if (!subDocIndex && !imageIndex) {
      return '';
    }
    if (!imageIndex) {
      const nextIndex = subDocIndex + direction;
      const file = filesData[nextIndex][filesData[nextIndex].length - 1];
      if (skipFile(file, question, direction)) {
        return getNext(filesData, nextIndex, filesData[nextIndex].length - 1, direction, question);
      }
      return `${nextIndex}/${filesData[nextIndex].length - 1}`;
    }
    const file = filesData[subDocIndex][imageIndex - 1];
    if (skipFile(file, question, direction)) {
      return getNext(filesData, subDocIndex, imageIndex - 1, direction, question);
    }
    return `${subDocIndex}/${imageIndex - 1}`;
  }
  return '';
}

const updatedFilesData = (
  filesData: IFilesData[][],
  subDocIndex: number,
  itemIndexes: number[],
  options: any,
) => (filesData.map((packageData: IFilesData[], index: number) => {
  if (index !== subDocIndex) return packageData;
  return packageData.reduce((
    acc: IFilesData[],
    element: IFilesData,
    elementIndex: number,
  ) => {
    if (itemIndexes.includes(elementIndex)) {
      acc.push({ ...element, ...options });
    } else {
      acc.push(element);
    }
    return acc;
  }, []);
}));

const updatedExpireDateData = (
  expiryDateData: IExpireDateData[],
  packagePosition: number,
  options: any,
) => (
  expiryDateData.reduce((
    acc: IExpireDateData[],
    element: IExpireDateData,
    elementIndex: number,
  ) => {
    if (packagePosition === elementIndex) {
      acc.push({ ...element, ...options });
    } else {
      acc.push(element);
    }
    return acc;
  }, [])
)

const getExpireDateActivePath = (
  expiryDateData: IExpireDateData[],
  filesSliderPath: string,
  prevPagePath: string,
) => {
  if (filesSliderPath) return Number(filesSliderPath);
  if (!filesSliderPath && prevPagePath && expiryDateData.length) return expiryDateData.length - 1;
  return 0;
}

const checkPackageDocumentDifference = (images: IFilesData[]) => {
  const validIndex = images.findIndex((imageData: IFilesData) => !isUnknownDocument(imageData));
  if (validIndex === -1) return false;
  const { country, documentType, extraDetail } = images[validIndex];
  return images.every((item: IFilesData) => (
    item.country === country
    && item.documentType === documentType
    && item.extraDetail === extraDetail
  ));
};

export {
  getNext,
  hasFacePage,
  validateFace,
  getFilesData,
  getCountryValue,
  updatedFilesData,
  getExpireDateData,
  getExtraDetailValue,
  getDocumentTypeValue,
  updatedExpireDateData,
  detectForcedRejection,
  getExpireDateActivePath,
  collectExtraDetailOptions,
  checkPackageDocumentDifference,
};