import { TCompareFields } from 'types';
import axios from 'util/axios';

export const SignInAPI = (email: string, password: string) => (
  axios.post('/signin', { email, password })
);

export const SignUpAPI = (email: string, name: string, password: string) => (
  axios.post('/signup', { email, name, password })
);

export const GetSpecimens = (documentType: string, country: string) => (
  axios.get('/specimens', { params: { documentType, country }})
);

export const SearchTransactionByCode = (code: string) => (
  axios.get('/search', { params: { code }})
);

export const GetZipContent = (code = '') => (
  axios.post('/fetchzip', { code })
);

export const GetHistory = (page = 1, size = 20) => (
  axios.get('/history', { params: { size, page }})
);

export const ExportFile = (totalItems: number) => (
  axios.get('/export', { params: { totalItems }})
);

export const GetActiveTransactions = (page = 1, size = 20) => (
  axios.get('/active-transactions', { params: { size, page }})
);

export const GetRequiredFields = (type: string) => (
  axios.get('/required-fields', { params: { type } })
);

export const SetDefaultDataset = (agentConfigId: number) => (
  axios.post('/setDataset', { agentConfigId })
);

export const CompleteTransaction = (data: any, code: string) => (
  axios.post('/complete', { data, code })
);

export const GetInstruction = () => axios.get('/instruction');

export const SendEmail = (data: any) => axios.post('/send-email', { data });

export const GetDatasets = () => axios.get('/datasets');

export const GetAuthConfig = () => axios.get('/authConfig');

export const GetAppVersion = () => axios.get('/appVersion');

export const GetDefaultConfigs = () => axios.get('/default-configs');

export const UpdateTransactionState = (data: any) => axios.post('/transaction-state', data);

export const Error = (message: string) => (axios.post('/log', message));

export const GetVideoContent = (code: string, entryName: string) => (
  axios.get('/video-content', { params: { code, entryName } })
)

export const CompareFields = (data: TCompareFields) => (
  axios.post('/compareWithTransliterations', data)
);
