import moment from 'moment';

import { IMAGE_RESOLUTION } from 'constants/Static';

import { getStorageData } from 'util/storageHelper';
import { EImageName, EPage, EVersionId } from 'enums';
import { TFileImage } from 'store/features/zipContent/types';
import { IGenericProps } from 'types';

export const parseRoute = (location: string) => {
  const arr = location.split('/');
  const result = {
    checkType: arr[1] as EPage,
    checkSubType: arr[2],
  };
  return result;
};

export const getImageFromBase64 = (image: string) => `data:image/jpg;base64, ${image}`;

export const formatDate = (date: Date, format = 'YYYY-MM-DD') => {
  let formattedDate = '';
  if (date) {
    formattedDate = moment(date).format(format);
  }
  return formattedDate;
};

export const detectImageCropping = (image: TFileImage, versionId: EVersionId) => (
  new Promise((resolve, reject) => {
    const imageSource = Object.values(image)?.[0] as string;
    const imageName = Object.keys(image)?.[0] as EImageName;
    if (versionId === EVersionId.v3) {
      resolve([EImageName.firstImage, EImageName.secondImage].some(v => imageName.includes(v)));
    } else {
      const imageData = getImageFromBase64(imageSource);
      const img = new Image();
      img.src = imageData;
      const isPortrait = img.width < img.height;
      const allowedWidth = isPortrait ? IMAGE_RESOLUTION.MIN : IMAGE_RESOLUTION.MAX;
      const allowedHeight = isPortrait ? IMAGE_RESOLUTION.MAX : IMAGE_RESOLUTION.MIN;
      img.onload = () => resolve(allowedWidth > img.width || allowedHeight > img.height);
      img.onerror = reject;
    }
  })
);

export const getUrlParams = () => {
  const urlParams: IGenericProps = {};
  const params = (new URL((window as any).location)).searchParams;
  params.forEach((value: string, key: string) => {
    urlParams[key] = value;
  });
  return urlParams;
};

export const getInstructionUrl = (lang: string) => {
  const instruction = JSON.parse(getStorageData('appConfig') || '{}').urls?.instruction;
  if (instruction?.[lang]) {
    return instruction[lang];
  }
  if (instruction?.en) {
    return instruction?.en;
  }
  return '';
};

export const downloadFile = (content: string) => {
  const date = moment().format('YYYYMMDD_HHmm');
  const blob = new Blob([content], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = `${date}_Reviewed_Transactions.csv`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

export const getCustomStyles = (active: boolean) => ({
  option: (base: any, stateData: any) => ({
    ...base,
    '&:hover': {
      backgroundColor: stateData.isFocused ? '#e0e0e0' : 'none',
      cursor: 'pointer',
    },
  }),
  control: (base: any) => ({
    ...base,
    borderColor: active ? '#E9425E !important' : 'none !important',
    marginBottom: '0.625rem',
    borderRadius: '1rem !important',
    minHeight: '2rem !important',
    width: '15rem !important',
    height: '2rem !important',
    border: '1px solid #707070',
    boxShadow: '0px 3px 6px #00000029'
  }),
  menu: (base: any) => ({
    ...base,
    borderRadius: '1rem !important',
  }),
  placeholder: (base: any) => ({
    ...base,
    fontSize: '0.95rem',
  }),
  valueContainer: (base: any) => ({
    ...base,
    height: '100%'
  }),
  indicatorsContainer: (base: any) => ({
    ...base,
    height: '100%'
  }),
  input: (base: any) => ({
    ...base,
    margin: 0,
  })
});