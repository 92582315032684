import { EDocumentType } from 'enums';
import { IGenericProps } from 'types';

export enum ZipContentActions {
  setZipContent = 'SET_ZIP_CONTENT',
  setZipContentLoader = 'SET_ZIP_CONTENT_LOADER',
}

export type TFileImage = { [key in string]: string };

export interface ICommonData {
  avatar: string;
  video: { entryName: string };
}

export interface IPackageData {
  subDocs: ISubDocData[];
  code: string;
  commonData: ICommonData;
  customerData: {
    coordinates: any;
    outputJson: any;
    utilityImages: Array<{
      base64: string;
      imageName: string;
      isCropped: boolean;
    }>;
    additionalDocImages: Array<{
      base64: string;
      imageName: string;
    }>;
  };
}

export interface ISubDocData {
  files: Array<TFileImage>;
  dataXML: IDataXML;
  reviewed: boolean;
  documentTypeData: IDocumentTypeData;
  avatar: string;
  videos: any[];
}

export interface IDocumentTypeData {
  classifiedDocument: IGenericProps;
  documentType: EDocumentType;
  documentCountry: string;
  documentSubtype: string;
}

export interface IDataXML {
  documentScan?: {
    $: any;
    viz?: any;
    mrz?: any;
    nfc?: any;
    barcode?: any;
    nfcBackend?: any;
    metadata?: {
      errorID?: any;
      errorCodes: string;
      versionId: string;
    };
    livenessCheck: any;
    faceVerification: any;
    IDFieldCoordinates: any;
  };
}

export interface ICustomerDataImage {
  base64: '';
  imageName: '';
  isCropped?: boolean;
}

export interface IZipContent {
  subDocsData: ISubDocData[];
  isMultipleDoc: boolean;
  avatar: string;
  video: {
    entryName: string;
  };
  trCode: string;
  zipContentLoaded: boolean;
  customerData?: any;
}

export type TZipContentActions =
  { type: ZipContentActions.setZipContent; value: IPackageData } |
  { type: ZipContentActions.setZipContentLoader; value: boolean };
