import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import { ReactComponent as Logo } from 'images/pxlCheck.svg';
import { ReactComponent as SuccessLogo } from 'images/success.svg';
import Input from 'components/input/Input';
import { SignUpAPI } from 'helper/api/route';
import { ISingleButtonProps } from 'types';
import Loader from 'components/loader/Loader';
import SwitchLanguage from 'components/lang/SwitchLanguage';
import SingleButton from 'components/buttonTypes/SingleButton';
import { BUTTON_STYLE, PATH } from 'constants/Static';

const { SIGN_IN } = PATH;

const SignUp = () => {
  const { t } = useTranslation();
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [isRegistered, setRegisterStatus] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isMatchPassword, setMatchStatus] = useState(true);
  const [isButtonActive, setButtonActive] = useState(false);
  const ref = useRef();
  const history = useHistory();

  const checkPassword = () => {
    const isMatch: boolean = confirmPassword === '' || (password === confirmPassword);
    const isActive = Boolean(email && name && password && confirmPassword === password);
    const errorMsg: string = !isMatch ? 'Please make sure your passwors match' : '';
    setMatchStatus(isMatch);
    setButtonActive(isActive);
    setErrorMessage(errorMsg);
  };

  const isValidEmail = (emailValue = '') => {
    const regExp = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailValue.match(regExp);
  };

  const signUpOnClickHandler: Function = () => {
    if (!isButtonActive || loader) return;
    if (!isValidEmail(email)) {
      setErrorMessage('Invalid email');
      return;
    }
    setLoader(true);
    SignUpAPI(email, name, password)
      .then(() => {
        setRegisterStatus(true);
      })
      .catch((err) => {
        if (err.response) {
          setLoader(false);
          setErrorMessage(err.response.data.message);
        }
      });
  };

  useEffect(() => {
    checkPassword();
  }, [name, email, password, confirmPassword]);

  const EmailInput = {
    className: 'input-container',
    handler: setEmail,
    placeholder: t('sign-up.input.placeholder.email'),
    type: 'text',
    label: t('sign-up.input.label.email'),
  };

  const UsernameInput = {
    className: 'input-container',
    handler: setName,
    placeholder: t('sign-up.input.placeholder.username'),
    type: 'text',
    label: t('sign-up.input.label.username'),
  };

  const PasswordInput = {
    className: 'input-container',
    handler: setPassword,
    placeholder: t('sign-up.input.placeholder.password'),
    type: 'password',
    label: t('sign-up.input.label.password'),
  };

  const ConfirmPasswordInput = {
    className: `input-container ${isMatchPassword ? '' : 'not-match-password'}`,
    handler: setConfirmPassword,
    placeholder: t('sign-up.input.label.confirm-password'),
    type: 'password',
    label: t('sign-up.input.label.confirm-password'),
    ref,
  };

  const buttonPropsData: ISingleButtonProps = {
    isActive: isButtonActive && !loader,
    onClick: signUpOnClickHandler,
    isDisabled: !isButtonActive || loader,
    textButton: 'sign-up.button',
    variant: BUTTON_STYLE.OUTLINE_SECONDARY,
    radiusButton: BUTTON_STYLE.CIRCLE_BUTTON,
  };

  const backButtonProps: ISingleButtonProps = {
    isActive: true,
    onClick: () => {history.replace(SIGN_IN);},
    textButton: 'sign-up.back-to-login',
    variant: BUTTON_STYLE.OUTLINE_SECONDARY,
    radiusButton: BUTTON_STYLE.CIRCLE_BUTTON,
  };

  return (
    <div className="main-content">
      <div className="container">
        <div className="login-container signup-container">
          <div className="identity-container">
            <Logo className={`${isRegistered ? 'logo' : ''}`} />
            {!isRegistered && <span className="identity-text">{t('sign-in.header')}</span>}
          </div>
          {isRegistered ? (
            <>
              <div className="result-container">
                <SuccessLogo className="success-icon" />
                <div>
                  <p className="success-text">{t('sign-up.success-text')}</p>
                  <p className="waiting-text">{t('sign-up.waiting-text')}</p>
                </div>
              </div>
              <div className="back-button">
                <SingleButton {...backButtonProps} />
              </div>
            </>
          ) : (
            <>
              <div className="form-container">
                <div className="error-container">{errorMessage}</div>
                { Input(EmailInput) }
                { Input(UsernameInput) }
                { Input(PasswordInput) }
                { Input(ConfirmPasswordInput) }
                <div className="button-container">
                  <SingleButton {...buttonPropsData} />
                  <div className="loader-container">
                    {loader && <Loader />}
                  </div>
                </div>
              </div>
              <div className="signup_container">
                <hr className="horizontal-line" />
                <div className="signin-from-signin">
                  <span>{t('sign-up.have-account')}</span>
                  <Link to={SIGN_IN}>{t('sign-up.sing-in')}</Link>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <SwitchLanguage />
    </div>
  );
};

export default SignUp;
