import i18n from 'i18next';

import { PERMIT, UNKNOWN } from 'constants/Static';
import { getStorageData } from 'util/storageHelper';
import { EDocumentType, EField, EZone } from 'enums';
import {
  ISubDocData,
  IZipContent,
  IDocumentTypeData,
} from 'store/features/zipContent/types';
import { IRootState } from 'store/types';
import { IUserInfo } from 'store/features/user/types';
import { IFailReason, IReviewResult } from 'types';
import {
  IFilesData,
  ICheckResult,
  IExpireDateData,
  IDataValidation,
  IDocumentData,
} from 'store/features/checkResult/types';
import { Questions, TDrivingCategory } from 'store/features/common/types';
import { drivingCategoryIsEmpty } from 'helper/validation';

const getTextByDefaultLang = (key: string) => {
  const { lang: langConfig } = JSON.parse(getStorageData('appConfig') || '');
  const lang = langConfig?.default || 'en';
  return i18n.getDataByLanguage(lang)?.translation?.[key] || '';
};

const getFailReasons = (data: string[]) => (
  data && data.length && data.map((reason: string, index: number) => ({
    $: { index },
    value: getTextByDefaultLang(reason),
  }))
) || [];

const getRejectReasons = (data: string[]) => (
  data && data.length && data.map((reason: string, index: number) => ({
    $: { index },
    _: getTextByDefaultLang(reason),
  }))
) || [];

const getQuickCheckResult = (checkResult: ICheckResult, subDocsData: ISubDocData[]) => {
  const { comparingData, filesData, expiryDateData, faceComparingData } = checkResult;
  let face = {};
  let data = {};
  if (faceComparingData?.quality) {
    const faceComparisonFailReasons = (
      faceComparingData?.reasons?.length && getFailReasons(faceComparingData.reasons)
    ) || [];
    face = {
      comparison: getTextByDefaultLang(faceComparingData?.quality),
      ...(faceComparisonFailReasons.length && { reason: faceComparisonFailReasons }),
    }
  }
  if (comparingData?.quality) {
    data = {
      comparison: getTextByDefaultLang(comparingData?.quality),
    }
  }
  const expiryDate = expiryDateData.map((item: IExpireDateData) => ({
    compareDate: item.reviewResult.key,
    $: { index: item.subDocIndex },
  }));
  const result = {
    quickCheck: {
      files: { package: [] },
      ...(expiryDate.length && { expiryDate: { package: expiryDate } }),
      ...(Object.keys(face).length && { face }),
      ...(Object.keys(data).length && { data }),
    },
  };
  const docs = filesData.map((subDoc: IFilesData[], subDocIndex: number) => {
    const images = subDoc.map((file: IFilesData, imgIndex: number) => {
      const fileName = Object.keys(subDocsData[subDocIndex].files[imgIndex])[0];
      const quality = (file.reviewResult.quality && {
        value: getTextByDefaultLang(file.reviewResult.quality),
      }) || {};
      const qualityReasons = getFailReasons(file.reviewResult?.reasons || []);
      return {
        $: {
          name: fileName,
        },
        ...(file.documentType && { documentType: file.documentType }),
        ...(file.country && { documentCountry: file.country }),
        ...(file.imageSide && { side: file.imageSide }),
        ...(quality?.value && {
          quality: {
            ...quality,
            ...(qualityReasons
              && qualityReasons.length && { reason: qualityReasons }),
          },
        }),
      };
    });
    return { $: { index: subDocIndex }, images };
  });
  result.quickCheck.files.package = docs as any;
  return docs.length ? result : {};
};

const getFraudCheckResult = (livenessData: IReviewResult, documentData: IDocumentData[][]) => {
  let liveness = {};
  if (livenessData.quality) {
    const livenessQualityFailReasons = (
      livenessData.reasons?.length && getFailReasons(livenessData.reasons)
    ) || [];
    liveness = {
      real: getTextByDefaultLang(livenessData.quality),
      ...(livenessQualityFailReasons.length && { reason: livenessQualityFailReasons }),
    }
  }

  const recordings = documentData.reduce((acc: any, subDoc) => {
    const videoData = subDoc.filter((document) => document.question === Questions.idRecording);
    if (videoData.length) {
      const [{ subDocIndex }] = videoData;
      const video = videoData.map((videoItem) => {
        const [, videoFileName = ''] = videoItem.entryName?.split('/') || [];
        const recordingQualityReasons = (videoItem.reviewResult.reasons?.length
            && getFailReasons(videoItem.reviewResult.reasons)
        ) || [];
        return {
          $: { name: videoFileName },
          real: getTextByDefaultLang(videoItem.reviewResult.quality),
          ...(recordingQualityReasons.length && { reason: recordingQualityReasons }),
        };
      });
      acc.push({ $: { index: subDocIndex }, video });
    }
    return acc;
  }, []);

  const fraudResult = documentData.reduce((acc: any, subDoc) => {
    const imageData = subDoc.filter((document) => document.question === Questions.imageSource);
    if (imageData.length) {
      const [{ subDocIndex }] = imageData;
      const image: any = [];
      imageData.forEach((imageItem) => {
        const { availableImageIndexes, reviewResult, specimen, hasSpecimen } = imageItem;
        const { duid = '' } = specimen || {};
        const fraudReasons: any[] = reviewResult.reasons?.length
          ? getFailReasons(reviewResult.reasons) : [];
        availableImageIndexes?.forEach((imageIndex) => {
          image.push({
            $: {
              index: imageIndex,
            },
            ...(reviewResult.quality && {
              templateComparison: getTextByDefaultLang(reviewResult.quality),
            }),
            ...(fraudReasons.length && { reason: fraudReasons }),
            duid: hasSpecimen ? duid : 'specimen not available',
          })
        });
        return {
        };
      });
      acc.push({ $: { index: subDocIndex }, image });
    }
    return acc;
  }, []);

  const result = {
    fraudCheck: {
      ...(Object.keys(liveness).length && { liveness }),
      ...(fraudResult.length && { package: fraudResult }),
      ...(recordings.length && { videos: { package: recordings}})
    },
  };

  return Object.keys(result.fraudCheck).length ? result : {};
};

const collectZoneData = (dataValidation: IDataValidation[], zone: EZone) => {
  const result: any = [];
  dataValidation.forEach((subDoc) => {
    const { zones, fields, subDocIndex } = subDoc;
    if (zones.includes(zone)) {
      const fieldsData = Object.keys(fields).reduce((acc: any, field: string) => {
        const { value, dlValue = [], modified, exists, disabled } = fields[field][zone];
        if (exists) {
          if (field === EField.drivingCategory) {
            const values = !drivingCategoryIsEmpty(dlValue) ?
              dlValue.map((el: TDrivingCategory, index: number) => ({
                $: { index }, ...{
                  value: el.value.reviewedValue,
                  issuingDate: el.issuingDate.reviewedValue,
                  ...(el.expiryDate.reviewedValue && { expiryDate: el.expiryDate.reviewedValue }),
                  ...(el.restrictions.reviewedValue && {
                    restrictions: el.restrictions.reviewedValue?.replace(/</gi, '*') || '',
                  }),
                },
              })) : [];
            acc[field] = {
              $: { edited: modified },
              ...{ fields: values },
            };
          } else {
            acc[field] = {
              $: { edited: modified },
              ...{ _: disabled ? '' : value?.replace(/</gi, '*') || '' },
            };
          }
        }
        return acc;
      }, {});
      result.push({
        $: {
          index: subDocIndex,
        },
        ...(Object.keys(fieldsData).length && { ...fieldsData }),
      });
    }
  });
  return Object.keys(result).length ? result : [];
};

const getDataCheckResult = (dataValidation: IDataValidation[]) => {
  const mrzData = collectZoneData(dataValidation, EZone.mrz);
  const vizData = collectZoneData(dataValidation, EZone.viz);
  const result = {
    dataCheckData: {
      ...(mrzData.length && { mrz: { package: mrzData } }),
      ...(vizData.length && { viz: { package: vizData } }),
    },
  };
  return Object.keys(result.dataCheckData).length ? result : {};
};

const getExtractedData = (extractedData: any) => ({
  package: Object.entries(extractedData).map(
    ([key, value]: [string, any]) => ({
      $: { index: key },
      data: value.data.length ? value.data.reduce((result: any, item: any) => {
        if (item.field !== EField.address) {
          result[item.field] = {
            $: { isMatch: !item.isActive },
            _: item.value,
          };
        }
        return result;
      }, {}) : getTextByDefaultLang('user-data-check.table.missing.data'),
      result: value.result,
      ...(value.reasons && {
        reasons: {
          value: getRejectReasons(value.reasons),
        },
      }),
    }),
  ),
});

const getCustomerDataCheckResult = (resultData: any) => {
  const { selfDeclaration = {}, address = {}, documentScan = {} } = resultData;
  const { data = {} } = selfDeclaration;
  const result = {
    userDataCheck: {
      ...(Object.keys(selfDeclaration).length && {
        selfDeclaration: {
          data,
          extractedData: getExtractedData(
            resultData.selfDeclaration.extractedData,
          ),
        },
      }),
      ...(Object.keys(address).length && {
        address: {
          imageName: address.image.imageName,
          data: address.data,
          result: address.result,
          ...(address.reasons && {
            reasons: {
              value: getRejectReasons(address.reasons),
            },
          }),
        },
      }),
      ...(Object.keys(documentScan).length && {
        documentScan: {
          imageName: documentScan.image.imageName,
          result: documentScan.result,
          ...(documentScan.reasons && {
            reasons: {
              value: getRejectReasons(documentScan.reasons),
            },
          }),
        },
      }),
    },
  };
  return Object.keys(result.userDataCheck).length ? result : {};
};

const getXMLData = (
  state: IRootState,
  result: string,
  sortedFailsList: IFailReason[] = [],
  message = '',
) => {
  const { userInfo, zipContent, customerDataCheck, checkResult = {} as ICheckResult } = state;
  const { dataValidation = [], livenessData, documentData } = checkResult;
  const { email = '' } = userInfo as IUserInfo;
  const { subDocsData = [], trCode = '' } = zipContent as IZipContent;
  const quickCheckData = getQuickCheckResult(checkResult, subDocsData);
  const fraudCheckData = getFraudCheckResult(livenessData, documentData);
  const dataCheckData = getDataCheckResult(dataValidation);
  const customerDataCheckData = getCustomerDataCheckResult(customerDataCheck);
  const targetXmlData = subDocsData[0]?.dataXML?.documentScan;
  const reasonsList: string[] = sortedFailsList.map((fail) => fail.reason);
  const { $: docScanAttribute = {} } = subDocsData[0].dataXML?.documentScan || {};
  const uniquEReasonList = Array.from(new Set(reasonsList));

  return {
    documentScan: {
      $: docScanAttribute,
      ...targetXmlData,
      manualCheck: {
        $: {
          date: new Date().toISOString(),
          agent: email,
          transactionID: trCode,
        },
        result,
        ...(uniquEReasonList.length && {
          reason: uniquEReasonList.join(','),
        }),
        ...(message && { message }),
        ...quickCheckData,
        ...fraudCheckData,
        ...dataCheckData,
        ...customerDataCheckData,
      },
    },
  };
};

const getXMLValueFromJSON = (key: any) => (typeof key === 'object' ? key._ : key);

const isPermit = (documentType: string) => documentType && documentType.includes(PERMIT);

const oneSideDocument = (documentType: EDocumentType) => (
  [EDocumentType.passport, EDocumentType.healthCard].includes(documentType)
);

const modifyDocumentSubType = (subType: string) => {
  if (!subType) {
    return subType;
  }
  return subType.substring(subType.length - 1);
};

const getDocumentTypeData = (dataXML: any) => {
  const result: IDocumentTypeData = {
    classifiedDocument: {
      front: UNKNOWN,
      back: UNKNOWN
    },
    documentType: '' as EDocumentType,
    documentCountry: '',
    documentSubtype: '',
  };
  const { documentScan = {} } = dataXML;
  const { mrz = {}, viz = {}, metadata = {}, documentTypeClassifier = {} } = documentScan;
  const { permitFormat = '' } = metadata;
  const {
    classifiedDocumentFirstScan = UNKNOWN,
    classifiedDocumentSecondScan = UNKNOWN,
  } = documentTypeClassifier;
  result.documentType = mrz.documentType ? getXMLValueFromJSON(mrz.documentType)
    : viz.documentType ? getXMLValueFromJSON(viz.documentType) : '';
  result.documentCountry = mrz.documentCountry ? getXMLValueFromJSON(mrz.documentCountry)
    : viz.documentCountry ? getXMLValueFromJSON(viz.documentCountry) : '';
  const documentSubtype: string = mrz.documentSubtype ? getXMLValueFromJSON(mrz.documentSubtype)
    : viz.documentSubtype ? getXMLValueFromJSON(viz.documentSubtype) : '';
  result.documentSubtype = modifyDocumentSubType(documentSubtype);
  if (classifiedDocumentFirstScan !== UNKNOWN) {
    const [classifiedDocument, side] = classifiedDocumentFirstScan.split(' ');
    result.classifiedDocument[side] = classifiedDocument;
  }
  if (classifiedDocumentSecondScan !== UNKNOWN) {
    const [classifiedDocument, side] = classifiedDocumentSecondScan.split(' ');
    result.classifiedDocument[side] = classifiedDocument;
  }
  if (permitFormat) {
    result.documentType = (`${permitFormat}-${PERMIT}` as EDocumentType);
  } else if (isPermit(result.documentType)) {
    result.documentType = Object.keys(mrz).length ?
      EDocumentType.plasticPermit : EDocumentType.paperPermit;
  }
  return result;
};

const getXMLDLDataFromJSON = (drivingCategory: any) => {
  const xmlDLData = drivingCategory && drivingCategory?.field
    ? drivingCategory?.field instanceof Array ?
      drivingCategory?.field : [drivingCategory?.field] : [];
  return xmlDLData.reduce((result: any, field: any, index: number) => {
    result[index] = {};
    Object.keys(field).forEach((item: string) => {
      if (item !== '$') result[index][item] = getXMLValueFromJSON(field[item]);
    });
    return result;
  }, []);
};

export {
  isPermit,
  getXMLData,
  oneSideDocument,
  getDocumentTypeData,
  getXMLValueFromJSON,
  getXMLDLDataFromJSON,
}