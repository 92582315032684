
import React from 'react';

interface ISignUpInputProps {
  className: string;
  handler: Function;
  placeholder: string;
  type: string;
  label: string;
  ref?: any;
}

const InputField = (props: ISignUpInputProps) => {
  const { className, handler, placeholder, type, label, ref } = props;

  return (
    <div className={className} ref={ref}>
      <input
        className="user-input"
        type={type}
        placeholder={placeholder}
        onChange={(e) => handler(e.target.value)}
      />
      <span
        className="placeholder"
      >
        {label}
      </span>
    </div>);
};

export default InputField;