import React, { useState, useEffect } from 'react';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import initI18n from 'i18n/i18n.config';

import { GetDefaultConfigs, SignInAPI } from 'helper/api/route';
import { ISingleButtonProps } from 'types';
import { ReactComponent as Logo } from 'images/pxlCheck.svg';
import { ReactComponent as EyeClose } from 'images/hide.svg';
import { ReactComponent as EyeOpen } from 'images/view.svg';
import Loader from 'components/loader/Loader';
import { getLocalStorageData, getStorageData } from 'util/storageHelper';
import SwitchLanguage from 'components/lang/SwitchLanguage';
import * as rootActions from 'store/actions';
import * as commonActions from 'store/features/common/actions';
import SingleButton from 'components/buttonTypes/SingleButton';
import { BUTTON_STYLE, KEY_CODE, PATH } from 'constants/Static';
import { IRootState } from 'store/types';

const { SIGN_UP } = PATH;

const isSignInButtonActive = (email: string, password: string) => {
  if (email !== '' && password !== '') {
    return false;
  }
  return true;
};

const SignIn = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const state: IRootState = useSelector((rootState: IRootState) => rootState);
  const appConfig = state.common?.appConfig || {};
  const { lang: langConfig } = appConfig;
  const dispatch = useDispatch();
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [eyeClose, setEye] = useState(false);
  const [loader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const isButtonActive: boolean = isSignInButtonActive(email, password);

  useEffect(() => {
    const localStorageData: string | null = getLocalStorageData('datasets');
    const sessionStorageData: string | null = getStorageData('state');
    if (localStorageData || sessionStorageData) {
      dispatch(rootActions.reset());
    }
  }, []);

  const signInOnClickHandler: Function = () => {
    if (!isButtonActive && !loader) {
      setLoader(true);
      SignInAPI(email, password)
        .then(() => {
          const urlParams = new URLSearchParams(location.search);
          const tid = urlParams.get('tid');
          if (tid) {
            dispatch(commonActions.setDefaultTransactionCode(tid));
          }
          history.push('/');
          if (Object.keys(appConfig).length && langConfig?.langs.length) {
            initI18n();
            setLoader(false);
          } else {
            GetDefaultConfigs().then((response) => {
              dispatch(commonActions.setAppConfig(response.data));
              initI18n(response?.data?.lang?.default || '');
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            setErrorMessage(err.response.data.message);
            setLoader(false);
          }
        });
    }
  };

  const buttonPropsData: ISingleButtonProps = {
    isActive: !isButtonActive && !loader,
    isDisabled: isButtonActive || loader,
    onClick: signInOnClickHandler,
    textButton: 'sign-in.button',
    variant: BUTTON_STYLE.OUTLINE_SECONDARY,
    radiusButton: BUTTON_STYLE.CIRCLE_BUTTON,
  };

  const keyPress: any = (e: KeyboardEvent) => {
    if (e.keyCode === KEY_CODE.ENTER) {
      signInOnClickHandler();
    }
  };

  return (
    <div className="main-content">
      <div className="container">
        <div className="login-container">
          <div className="identity-container">
            <Logo />
            <span className="identity-text">{t('sign-in.header')}</span>
          </div>
          <div className="form-container">
            <div className="error-container">{errorMessage}</div>
            <div className="input-container">
              <input
                className="user-input"
                type="text"
                placeholder={t('sign-in.input.placeholder.username')}
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={(e) => keyPress(e)}
              />
              <span
                className="placeholder"
              >
                {t('sign-in.input.label.username')}
              </span>
            </div>
            <div className="input-container">
              {
                eyeClose ? <EyeOpen
                  onClick={() => setEye(!eyeClose)}
                /> : <EyeClose
                  onClick={() => setEye(!eyeClose)}
                />
              }
              <input
                className="user-input right-addon"
                type={`${eyeClose ? 'text' : 'password'}`}
                placeholder={t('sign-in.input.placeholder.password')}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={(e) => keyPress(e)}
              />
              <span
                className="placeholder"
              >
                {t('sign-in.input.label.password')}
              </span>
            </div>
            <div className="button-container">
              <SingleButton {...buttonPropsData} />
              <div className="loader-container">
                {loader && <Loader />}
              </div>
            </div>
          </div>

          <div className="signup_container">
            <hr className="horizontal-line" />
            <div className="signup-from-signin">
              <span>{t('sign-in.footer.not-account')}</span>
              <Link to={SIGN_UP}>{t('sign-in.footer.sign-up')}</Link>
            </div>
          </div>
        </div>
      </div>
      <SwitchLanguage />
    </div>
  );
};

export default SignIn;
